import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Gallery from "react-photo-gallery";
import ArdsleyTitle from "../components/ArdsleyTitle";
// import '../components/GalleryFix.css'
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

export const GalleryPageTemplate = ({ pictures, content, title }) => {
  const photos = pictures
    .filter((p) => !!p.image)
    // .map((p) => ({
    //   width: p.image.childImageSharp.fluid.aspectRatio,
    //   height: 1,
    //   src: p.image.childImageSharp.fluid.src,
    // }));
    .map(p => ({
      original: p.image.childImageSharp.fluid.src,
      thumbnail: p.image.childImageSharp.fluid.src
    }))

  return (
    <section className="section section--gradient">
      <div className="container">
        <ArdsleyTitle text={title} />
        <HTMLContent content={content} />
        <ImageGallery
          items={photos}
          showFullscreenButton={false}
          thumbnailPosition="bottom"
        />
      </div>
    </section>
  );
};

const GalleryPage = ({ data }) => {
  console.log("DATA", data);

  const {
    frontmatter: { image, title, pictures },
    html,
  } = data.markdownRemark;

  return (
    <Layout>
      <GalleryPageTemplate pictures={pictures} content={html} title={title} />
    </Layout>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.object,
};

export default GalleryPage;

export const galleryPageQuery = graphql`
  query galleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pictures {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
